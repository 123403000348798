import React from 'react'
import ReactDOM from 'react-dom/client';
import './globals.css'
import DataEngineerPortfolio from './components/data-engineer-portfolio'

export const metadata = {
  title: 'Abdellah Boufous - Data Engineer Portfolio',
  description: 'Data Engineer specializing in AWS, Terraform, and building scalable data infrastructure',
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <DataEngineerPortfolio />
  </React.StrictMode>
);